var exports = {};
exports.parse = parse;
exports.stringify = stringify;
var empty = "";
var space = " ";
var whiteSpace = /[ \t\n\r\f]+/g;

function parse(value) {
  var input = String(value || empty).trim();
  return input === empty ? [] : input.split(whiteSpace);
}

function stringify(values) {
  return values.join(space).trim();
}

export default exports;
const _parse = exports.parse,
      _stringify = exports.stringify;
export { _parse as parse, _stringify as stringify };